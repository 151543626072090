<template>
  <el-row :gutter="20">
    <el-col
        :xs="8"
        :sm="8"
        :md="8"
        :lg="8"
        :xl="8"
    >
      <div class="card">
        <h4 class='card-title'>New Signups</h4>
        <div v-for="item in newregistrations" v-bind:key="item.id" class="card-row">
          <div class='card-name'>{{ item.sender_name }}</div>
          <div class='card-col-item'>{{ item.date_text }}</div>
          <div class='card-col-item'>{{ item.sender_email }}</div>
          <div class='card-col-item'><i class='fa fa-phone'></i> {{ item.sender_phone }}</div>
          <div class='card-icon-box'>
            <span class="card-icons"><i class="fa fa-phone fa-lg"></i><sup> 0</sup></span>
            <span class="card-icons"><i class="fa fa-envelope fa-lg"></i><sup> 0</sup></span>
            <span class="card-icons"><i class="fa fa-sticky-note fa-lg"></i><sup> 0</sup></span>
            <span class="card-icons"><i class="fa fa-clock fa-lg"></i><sup> 0</sup></span>
            <span class="card-icons"><i class="fa fa-search fa-lg"></i><sup> 0</sup></span>
            <span class="card-icons"><i class="fa fa-eye fa-lg"></i><sup> 0</sup></span>
            <span class="card-icons"><i class="fa fa-star fa-lg"></i><sup> 0</sup></span>
            <span class="card-icons"><i class="fa fa-history fa-lg"></i></span>
          </div>
        </div>
        <div class='link-view-leads'><a href="/leads">View Leads...</a></div>
      </div>
      <div class='space20'></div>
      <div class="card">
        <h4 class='card-title'>Most Viewed Listings</h4>
        <div v-for="item1 in listingsviewed" v-bind:key="item1.id" class="card-listing-name">
          {{ item1.address }}
        </div>
      </div>
    </el-col>
    <el-col
        :xs="16"
        :sm="16"
        :md="16"
        :lg="16"
        :xl="16"
    >
      <div class="card">
        <h4 class='card-title'>Quick Links</h4>
        <div class='card-icon-box'>
          <ul class="quicklink_content">
            <li class="col-sm-4"><a href="/setting/setting">Edit Social Links</a></li>
            <li class="col-sm-4"><a href="/profile">Edit Introduction Text</a></li>
            <li class="col-sm-4"><a href="/banners">Edit Slider Images</a></li>
            <li class="col-sm-4"><a href="/profile">Profile Photos</a></li>
            <li class="col-sm-4"><a href="/domains">Add/Edit Website Domain</a></li>
            <li class="col-sm-4"><a href="#">Email Signature</a></li>
          </ul>
        </div>
      </div>

    </el-col>
  </el-row>
</template>

<script>
import LeadsService from "@/api/leads.service";
import TrackpropertyService from "@/api/trackproperty.service";

export default {
  name: "RealtorDashboard",
  data() {
    return {
      newregistrations: [],
      listingsviewed: [],
      page: 1,
      search: [],
    }
  },
  mounted() {
    this.leads();
    this.getmostviewdProperties();
  },
  methods: {
    async leads() {
      this.search.per_page = 10;
      return LeadsService.leads(this.page, this.search).then(response => {
        this.newregistrations = response.data.data;
      });
    },
	async setSettingUrl() {
	  return UserService.getUserBoard().then(response => {  
		this.$router.push({ path: "/setting/"+response.data.domains[0].id });
	  });
	},
    async getmostviewdProperties() {
      this.search.per_page = 10;
      return TrackpropertyService.mostvisitedProperties(this.page, this.search).then(response => {
        this.listingsviewed = response.data.data;
      });
    },
  }
}
</script>

<style scoped>
.card-title {
  font-size: 18px;
  text-align: left;
  font-weight: 500;
  line-height: 1.2;
  color: var(--el-table-font-color);
  background: #f5f7fa;
  margin: 0px;
  padding: 8px;
}

.card-row {
  text-align: left;
  color: var(--el-table-font-color);
  min-height: 130px;
  height: auto;
  margin: 10px auto;
  border-bottom: 1px solid lightgray;
  font-size: 14px;
  padding: 0px 5px 0px 8px;
}

.card {
  background: #fcfcfc;
  border: 1px solid #ebe9f1;
  box-shadow: none;
  border-radius: 5px;
}

.card-name {
  padding-top: 5px;
  color: #6097c6;
  text-transform: capitalize;
}

.card-col-item {
  color: #848383;
  padding-top: 5px;
}

.card-icons {
  padding-right: 0.7em;
  color: #8e8c8c;
}

.card-icon-box {
  padding: 10px 0px;
}

.link-view-leads {
  text-align: right;
  color: #6ea0cb;
  padding: 5px;
  font-size: 13px;
}

.quicklink_content li {
  padding: 2px 0px;
  width: 33%;
  text-align: left;
  display: inline-block;
  color: #6097c6
}

.quicklink_content a {
  font-size: 13px;
}

.space20 {
  height: 20px;
}

.card-listing-name {
  text-align: center;
  font-size: 14px;
  color: #6097c6;
  padding: 10px;
  border-bottom: 1px solid lightgray;
}
</style>
